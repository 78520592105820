<template>
  <div>
    <router-link
      class="btn btn-primary mb-3"
      :to="{name: 'upload-certificate'}"
    >
      Upload New Certificate
    </router-link>
    <div class="table-responsive">
      <table class="table table-hover text-nowrap">
        <thead>
          <tr>
            <th>Certificate Name</th>
            <th>Certificate URL</th>
            <th>OCN</th>
            <th>Company Name</th>
            <th>Expired On</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
          </tr>
          <tr>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
          </tr>
        </tbody>
      </table>
      <a-pagination
        v-if="total"
        class="pagination-right"
        :default-current="1"
        :total="total"
        @change="pagination($event)"
      />
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import {Modal} from 'ant-design-vue'

export default {
  data() {
    return {
      step: 10,
      skip: 0,
      total: null,
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    pagination(int) {
      this.skip = 0
      this.skip += this.step * int - this.step
      this.loadItems()
    },
    loadItems() {
      //   axios
      //     .get(`user?take=${this.step}&skip=${this.skip}`)
      //     .then((res) => {
      //       this.items = res.data.data
      //       this.total = res.data.count
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
    },
  },
}
</script>

<style scoped>
.pagination-right {
  text-align: right;
  margin-right: 210px;
}
</style>
